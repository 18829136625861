
import { PlayerManage_GMHandlePlayerBagItem, PlayerManage_GMSearchPlayerInfo } from '@/apis/player_manage';
import { GetAllItem, GetItemName } from '@/constant/itemconfig';
import { ElForm, ElMessage } from 'element-plus';
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

    setup() {
        const { t } = useI18n()
        const dataForm = ref<typeof ElForm>()
        const dataMap = reactive({
            time: Date,
            tableKey: 0,
            total: 1,
            listLoading: true,
            logdbInfoLoading: false,
            account: "",
            accountInfo: {} as ISearchPlayerInfo,
            proptags: [] as { name: string, value: string }[],
            allitems: [] as { name: string, value: number }[],
            dialogFormVisible: false,
            tempLogRecord: { ItemConfigid: 0, ItemCount: 1 },
        })
        const allitems = GetAllItem();
        for (let k in allitems) {
            dataMap.allitems.push({ name: allitems[k].ItemName, value: Number(k) })
        }

        const GetTheItemName = (v: string) => {
            return GetItemName(v) || "";
        }
        const GetTimeDes = (v: string) => {
            if (Number(v) && Number(v) < 0) {
                return "永久"
            }
            return new Date(Number(v)).toLocaleString()
        }

        const handleSearch = async () => {
            dataMap.listLoading = true;
            dataMap.proptags.length = 0;
            if (dataMap.account == "") {
                ElMessage.error(`请输入查询的账号`);
                return;
            }
            let cbmsg = await PlayerManage_GMSearchPlayerInfo({
                Account: dataMap.account
            });
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`查询失败: ${cbmsg?.Message}`);
                return;
            }
            if (cbmsg.Message && cbmsg.Message.length > 0) {
                let record = JSON.parse(cbmsg.Message) as ISearchPlayerInfo;
                if (record.Child && record.Child.length > 0) {
                    const entitys = JSON.parse(record.Child) as ETEntity[];
                    for (let info of entitys) {
                        console.log(info);
                        (record as any)[info._t] = info;
                    }
                    dataMap.accountInfo = record;
                    dataMap.proptags.push({ name: "Account", value: dataMap.account })
                    dataMap.proptags.push({ name: "Online", value: Boolean(record.online) + "" })
                    if (record.TCharacter) {
                        dataMap.proptags.push({ name: "CreateTime", value: GetTimeDes(record.TCharacter.CreateTime) })
                        dataMap.proptags.push({ name: "LastLoginTime", value: GetTimeDes(record.TCharacter.LastLoginTime) })
                        dataMap.proptags.push({ name: "VipType", value: record.TCharacter.VipType + "" })
                        dataMap.proptags.push({ name: "VipEndTimeSpan", value: GetTimeDes(record.TCharacter.VipEndTimeSpan) })
                    }
                    if (record.CharacterBattlePassComponent) {
                        dataMap.proptags.push({ name: "BattlePassLevel", value: record.CharacterBattlePassComponent.BattlePassLevel + "" })
                        dataMap.proptags.push({ name: "IsBattlePass", value: record.CharacterBattlePassComponent.IsBattlePass + "" })
                        dataMap.proptags.push({ name: "BattlePassPrizeGet", value: record.CharacterBattlePassComponent.BattlePassPrizeGet + "" })
                    }


                }
            }
            dataMap.listLoading = false;
        }
        const handleAddItem = () => {
            dataMap.dialogFormVisible = true;
        }
        const handleSureAddItem = async () => {
            if (GetAllItem()[dataMap.tempLogRecord.ItemConfigid] == null) {
                ElMessage.error("请输入正确的道具id")
                return;
            }
            if (dataMap.tempLogRecord.ItemConfigid <= 0 || dataMap.tempLogRecord.ItemCount <= 0) {
                ElMessage.error("请输入正确的道具id和数量")
                return;
            }
            let cbmsg = await PlayerManage_GMHandlePlayerBagItem({
                ItemConfigId: dataMap.tempLogRecord.ItemConfigid,
                ItemCount: dataMap.tempLogRecord.ItemCount,
                Account: dataMap.account,
                HandleType: 0,
            })
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`添加失败: ${cbmsg?.Message}`);
                return;
            }
            ElMessage.success(`添加成功: ${GetTheItemName(dataMap.tempLogRecord.ItemConfigid + "")} x ${dataMap.tempLogRecord.ItemCount}`);
            dataMap.dialogFormVisible = false;
        }

        onMounted(() => {
        })

        return {
            t, ...toRefs(dataMap),
            dataForm,
            GetTheItemName,
            handleSearch,
            handleAddItem,
            handleSureAddItem
        }
    }
})

