/*
 * @Description: 玩家數據
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const PlayerManage_GMSearchPlayerInfo = (data: IC2G_GMSearchPlayerInfo) => {
    return https().request<IHttpResponse>('/GMSearchPlayerInfo', Method.POST, data, ContentType.json)
}

export const PlayerManage_GMHandlePlayerBagItem = (data: IC2G_GMHandlePlayerBagItem) => {
    return https().request<IHttpResponse>('/GMHandlePlayerBagItem', Method.POST, data, ContentType.json)
}