<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input v-model="account" :placeholder="`Account`" style="width: 200px;" class="filter-item" />
            <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleSearch">
                {{ "查找玩家" }}
            </el-button>
        </div>
        <div class="filter-container" v-if="accountInfo.Child">
            <el-button type="primary" size="mini" @click="handleAddItem">
                {{ "添加道具" }}
            </el-button>
            <el-button type="danger" size="mini">
                {{ "封号" }}
            </el-button>
        </div>

        <div style=" margin-top: 10px;">
            <label class="label" v-for="item of proptags" :key="item.name" style="margin-right:15px;">
                {{ item.name }}
                <el-tag :key="item.name" style="margin-right:15px;">
                    {{ item.value }}
                </el-tag>
            </label>

        </div>

        <el-dialog v-model="dialogFormVisible" :title="'添加道具'">
            <label class="label" for="ItemConfigid">
                物品Id:
                <el-select v-model="tempLogRecord.ItemConfigid" style="width: 140px" class="filter-item">
                    <el-option v-for="item in allitems" :key="item.value" :label="item.name + '[' + item.value + ']'"
                        :value="item.value" />
                </el-select>
            </label>
            <label class="label" for="ItemCount">
                数量:
                <input v-model.number="tempLogRecord.ItemCount" type="number" name="ItemCount">
            </label>
            <label class="label" :key="`道具名称`" style="margin-right:15px;">
                {{ "道具名称" }}
                <el-tag :key="`道具名称`" style="margin-right:15px;">
                    {{ GetTheItemName(tempLogRecord.ItemConfigid + "") }}
                </el-tag>
            </label>
            <el-button type="danger" size="mini" @click="handleSureAddItem">
                {{ "确定" }}
            </el-button>
        </el-dialog>
    </div>
</template>
  
<script lang="ts">
import { PlayerManage_GMHandlePlayerBagItem, PlayerManage_GMSearchPlayerInfo } from '@/apis/player_manage';
import { GetAllItem, GetItemName } from '@/constant/itemconfig';
import { ElForm, ElMessage } from 'element-plus';
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

    setup() {
        const { t } = useI18n()
        const dataForm = ref<typeof ElForm>()
        const dataMap = reactive({
            time: Date,
            tableKey: 0,
            total: 1,
            listLoading: true,
            logdbInfoLoading: false,
            account: "",
            accountInfo: {} as ISearchPlayerInfo,
            proptags: [] as { name: string, value: string }[],
            allitems: [] as { name: string, value: number }[],
            dialogFormVisible: false,
            tempLogRecord: { ItemConfigid: 0, ItemCount: 1 },
        })
        const allitems = GetAllItem();
        for (let k in allitems) {
            dataMap.allitems.push({ name: allitems[k].ItemName, value: Number(k) })
        }

        const GetTheItemName = (v: string) => {
            return GetItemName(v) || "";
        }
        const GetTimeDes = (v: string) => {
            if (Number(v) && Number(v) < 0) {
                return "永久"
            }
            return new Date(Number(v)).toLocaleString()
        }

        const handleSearch = async () => {
            dataMap.listLoading = true;
            dataMap.proptags.length = 0;
            if (dataMap.account == "") {
                ElMessage.error(`请输入查询的账号`);
                return;
            }
            let cbmsg = await PlayerManage_GMSearchPlayerInfo({
                Account: dataMap.account
            });
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`查询失败: ${cbmsg?.Message}`);
                return;
            }
            if (cbmsg.Message && cbmsg.Message.length > 0) {
                let record = JSON.parse(cbmsg.Message) as ISearchPlayerInfo;
                if (record.Child && record.Child.length > 0) {
                    const entitys = JSON.parse(record.Child) as ETEntity[];
                    for (let info of entitys) {
                        console.log(info);
                        (record as any)[info._t] = info;
                    }
                    dataMap.accountInfo = record;
                    dataMap.proptags.push({ name: "Account", value: dataMap.account })
                    dataMap.proptags.push({ name: "Online", value: Boolean(record.online) + "" })
                    if (record.TCharacter) {
                        dataMap.proptags.push({ name: "CreateTime", value: GetTimeDes(record.TCharacter.CreateTime) })
                        dataMap.proptags.push({ name: "LastLoginTime", value: GetTimeDes(record.TCharacter.LastLoginTime) })
                        dataMap.proptags.push({ name: "VipType", value: record.TCharacter.VipType + "" })
                        dataMap.proptags.push({ name: "VipEndTimeSpan", value: GetTimeDes(record.TCharacter.VipEndTimeSpan) })
                    }
                    if (record.CharacterBattlePassComponent) {
                        dataMap.proptags.push({ name: "BattlePassLevel", value: record.CharacterBattlePassComponent.BattlePassLevel + "" })
                        dataMap.proptags.push({ name: "IsBattlePass", value: record.CharacterBattlePassComponent.IsBattlePass + "" })
                        dataMap.proptags.push({ name: "BattlePassPrizeGet", value: record.CharacterBattlePassComponent.BattlePassPrizeGet + "" })
                    }


                }
            }
            dataMap.listLoading = false;
        }
        const handleAddItem = () => {
            dataMap.dialogFormVisible = true;
        }
        const handleSureAddItem = async () => {
            if (GetAllItem()[dataMap.tempLogRecord.ItemConfigid] == null) {
                ElMessage.error("请输入正确的道具id")
                return;
            }
            if (dataMap.tempLogRecord.ItemConfigid <= 0 || dataMap.tempLogRecord.ItemCount <= 0) {
                ElMessage.error("请输入正确的道具id和数量")
                return;
            }
            let cbmsg = await PlayerManage_GMHandlePlayerBagItem({
                ItemConfigId: dataMap.tempLogRecord.ItemConfigid,
                ItemCount: dataMap.tempLogRecord.ItemCount,
                Account: dataMap.account,
                HandleType: 0,
            })
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`添加失败: ${cbmsg?.Message}`);
                return;
            }
            ElMessage.success(`添加成功: ${GetTheItemName(dataMap.tempLogRecord.ItemConfigid + "")} x ${dataMap.tempLogRecord.ItemCount}`);
            dataMap.dialogFormVisible = false;
        }

        onMounted(() => {
        })

        return {
            t, ...toRefs(dataMap),
            dataForm,
            GetTheItemName,
            handleSearch,
            handleAddItem,
            handleSureAddItem
        }
    }
})

</script>
<style lang="scss" scoped>
.count {
    font-size: 50px;
    color: #f6416c;
    display: block;
    margin: 10px 0;
    text-align: center;
    font-size: 80px;
    font-weight: 500;
}

.label {
    color: #2f4f4f;
    font-size: 16px;
    display: inline-block;
    margin: 15px 30px 15px 0;
}
</style>